import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
// import { apiUrl } from '../../../config'
import { identityServices } from '../../common'
import { ImportData } from '../../common/base/entity/components/ImportData'
import { getRegexMatch, validateType } from '../../common/helpers/validators'
class ImportTsvComponent extends ImportData {
	constructor(props) {
		super(props)
		this.downloadTemplate.bind(this)
		this.state = {
			tenantRoles: [],
			acceptedFiles: null,
			disallowedTeam: false,
			disallowedDefault: false,
			team: null,
			defaultTeam: null,
			activeAttributes: [],
			modal: false,
			requestId: null,
		}
	}

		componentDidMount() {
			const {
				profile: { tenantId, tenantRoles },
			} = this.props;
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				url: `${
					this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl) || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
				}/UserAttribute`,
			}
			identityServices.identityApi.
			request(requestOptions).
			then((res)=>{
				const activeAttributes = res?.data?.filter(attribute => attribute.status === 'Active');
				this.setState({ activeAttributes });
			}).catch(err => {
				toast.error(err.message)
			})
		}
	validate() {
		const { records, tenantRoles } = this.state
		const { meta } = this.props
		const { fields } = meta || {}
		if (!records || !records.length) {
			toast.error(`No records were found in the uploaded file. Please upload again.`)
			this.cancel()
			return
		}
		let headers = records && records[0]
		if (headers && headers.filter(h => meta.importKeys && meta.importKeys.find(k => meta.fields[k] && meta.fields[k].label === h)).length !== headers.length) {
			toast.error(`Please upload template with right headers. Download Template from the link below.`)
			this.cancel()
			return
		}

		// let keys = meta.importKeys.filter( k => headers.find( h => meta.fields[k] && meta.fields[k].label === h))
		let keys = []
		headers.forEach(h => {
			let f = Object.values(meta.fields).find(f => f.label === h)
			meta.importKeys && meta.importKeys.includes(f.name) && keys.push(f.name)
		})
		let recs = []
		records.forEach((r, i) => {
			if (i === 0) return null
			let vals = r
			let obj = {}
			headers.forEach((k, j) => {
				let f = Object.values(meta.fields).find(f => f.label === k)
				if (f && f.name) obj[f.name] = vals[j] && this.fixTypes(vals[j].trim())
			})
			if (obj) recs.push(obj)
		})
		if (recs && recs.length == 0) {
			toast.error(`No records were found in the uploaded file. Please upload again.`)
			this.cancel()
			return
		}
		recs = recs.filter(r => r)
		let validationErrors
		let finalRecs = []
		if (meta.recordValidator) {
			validationErrors = meta.recordValidator(recs)
		} else {
			validationErrors = []

			for (let i = 1; i < records.length; i++) {
				if (records[i].length > headers.length) {
					validationErrors.push(`Row ${i + 1} has more columns than expected. Please check the file and upload again.`);
				}
			}

			recs.forEach((r, i) => {
				let flag = false
				let teamErrors = new Set(); 
				const allKeysEmptyOrUndefined = Object.values(r).every(value => value === "" || value === undefined);
				if(allKeysEmptyOrUndefined){
					return validationErrors.push(`Row ${i + 1} has a empty values. Please check the file and try again.`)
				}
				keys.forEach(k => {
					let f = fields && fields[k]
					let e = ''
					if (!f) e += `Meta error for field ${k}`
					if (f.required && (r[k] == undefined || r[k] == '')) {
						e += `${f.label} is mandatory;`
						flag = true
					} else if (f.regexPattern) {
						if (!getRegexMatch(f.regexPattern, r[k])) {
								flag = true
								e += `${f.label} pattern does not match ${f.regexPattern};`
						}
					}
					if (r && k && k === "username" && r.username !== r.email) {
						e += `UserName ${r.username} and Email ${r.email} should be the same;`
						flag = true
					}
					let teamData = this.props.tenant.team.team;
					if(k === 'attributes') {
						let attributesR  = Array.isArray(r.attributes) ? r.attributes : [r.attributes];
						let attributes = {}
						attributesR.forEach(item => {
							if (typeof item === 'string') {
								item.replace(/([^|:]+):\s*(("[^"]+(?:"\s*,\s*"[^"]+)*")|[^|]+)/g, (_, key, value) => {
									key = key.trim();
								
									if (value.startsWith('"') && value.endsWith('"')) {
										// Handle multiple quoted values separated by commas
										value = value.slice(1, -1);
										attributes[key] = value.split(/",\s*"/).map(v => v.trim());
									} else {
										// Handle non-quoted values and remove duplicates
										attributes[key] = Array.from(new Set(value.split(',').map(v => v.trim())));
									}
								})
							}
						});
						r.attributes = attributes
						let disallowedTeams = attributes.team ? attributes.team.filter(element => !teamData.includes(element)) : []
						let	disallowedDefaultTeams = attributes.defaultTeam ? attributes.defaultTeam.filter(element => !teamData.includes(element)) : []
						if (disallowedTeams.length > 0) {
							flag = true
							disallowedTeams.forEach(team => teamErrors.add(team)) 
						} else if (disallowedDefaultTeams.length > 0) {
							flag = true
							disallowedDefaultTeams.forEach(team => teamErrors.add(team))
						}
						
						let needMandAttrKy = ""
						this.state.activeAttributes.forEach(att => {
							const isRequired = att.validationRule.required === 'Yes'
							const isAttributeMissing = !Object.keys(attributes).includes(att.key)
						
							if (isRequired && isAttributeMissing) {
								needMandAttrKy += att.key + ",";
							}
						})
						if(needMandAttrKy!==""){
							validationErrors.push(`Row ${i + 1}: Attributes '${needMandAttrKy}' is Required .`);
								flag = true;
						}

						Object.keys(attributes).forEach(attrKey => {
							const attribute = this.state.activeAttributes.find(attr => attr.key === attrKey);
							if (attribute && attribute.isMultivalue === "No" && attributes[attrKey].length > 1) {
								validationErrors.push(`Row ${i + 1}: Attribute '${attrKey}' does not allow multiple values.`);
								flag = true;
							}
						});
					}
					
					if(f.name === "email")
					  {
						if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(r[k])) 
							{
								flag = true
								e += `${f.label} pattern does not match,please enter valid email address ;`
							}	
					  }
					let tOptions = f.options
					if (f.label && f.label === 'Roles' && tenantRoles) {
						tOptions = tenantRoles
					}
					let err = r[k] && validateType({ type: f.type, value: r[k], label: f.label, options: tOptions || [], isMulti: f.isMulti })
					if (err) {
						e += err
						flag = true
					} else if (f.type === 'select' && r[k]) {
						r[k] = r[k].split(',')
					}
			
					if (flag && e !== '') {
						validationErrors.push(`Row: ${i + 1}: ${e}`)					}
				});
				teamErrors.forEach(team => validationErrors.push(`Row: ${i + 1}: This '${team}' team is not there in tenant.`));
				const rolesToMatch = r.roles.split(',').map(role => role.trim())
				for (let j = 0; j < rolesToMatch.length; j++) {
					const role = rolesToMatch[j];
					if (!tenantRoles.includes(role)) {
						validationErrors.push(`Row: ${i + 1}: Please enter valid roles.`)
						break
					}
				}		
				if (!flag) {
					r['enabled'] = true
					finalRecs.push(r);
				}
			});
		}
		let tenantRolesSet = new Set(tenantRoles);
		finalRecs.forEach((user, i) => {
			user.roles = user.roles ? user.roles.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ').trim() : "Ticket User,Bot User";
			let roles = user.roles.split(',')
			let unexpectedRoles = []
			roles.forEach(role => {
				if (!tenantRolesSet.has(role)) {
					unexpectedRoles.push(role)
				}
			})
			if (unexpectedRoles.length > 0) {
				validationErrors.push(`Row ${i + 1}: '${unexpectedRoles.join(',')}' Role not Accepted.`)
			}
		})

		validationErrors = validationErrors && validationErrors.pop && validationErrors.filter(e => e)
		if (validationErrors && validationErrors.length && validationErrors.length > 0) this.setState({ ...this.state, newFile: false, validationErrors, validated: true })
		// validate the uploaded records for cols schema and unique constraint if any.
		else if (this.props.validateItems) {
			this.setState({ ...this.state, serverValidationRequested: true, validationErrors: undefined, records: finalRecs, newFile: false, valServerErrors: undefined }, () => {
				this.props.validateItems({ items: finalRecs, method: 'bulkUpsert' })
			})
		} else this.setState({ ...this.state, validated: true, validationErrors: undefined, records: finalRecs, newFile: false, valServerErrors: undefined })
	}
	import() {
		const ImportItem = this.state.records.map(item => ({
			...item, 
			username: item.username.toLowerCase(),  
			email: item.email.toLowerCase()        
		}))
		if (!this.props.importItems) console.error(`Import action is not found.`)
		else{
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json','clientApp':'botapi','realmName' : this.props.tenant.tenantId },
				data: ImportItem,
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/role-management/bulkUpsertByJob?type=ImportUserFromTSV`,
			}
			return identityServices.identityApi
				.request(requestOptions)
				.then(response => {
					if (response.status === 200) {
						this.setState({
							modal: true,
							requestId: response.data.requestId
						})
						return Promise.resolve(response)
					}
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		}
		// else this.props.importItems({ items: ImportItem, t: this.props.t })
	}

	componentWillMount() {
		const {
			profile: { tenantId, tenantRoles },
		} = this.props
		if (tenantId) {
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/role/tenant-roles/null?tenantId=${tenantId}`,
			}
			return identityServices.identityApi
				.request(requestOptions)
				.then(response => {
					if (response.status != 200) {
						return Promise.reject(response)
					}
					let allowedRoles = []
					if (response.data && Array.isArray(response.data) && response.data.length > 0) {
						response.data.forEach(x => x.name && allowedRoles.push(x.name))
					} else {
						allowedRoles = tenantRoles
					}
					this.setState({ tenantRoles: allowedRoles })
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		}
	}

	componentWillReceiveProps(props) {
		if (this.state.serverValidationRequested && props.entity && !props.entity.loading)
			this.setState({ ...this.state, valServerErrors: props.valServerErrors || (props.entity && props.entity.error), validated: true })
		if (this.state.validated && props.entityList && !props.entityList.loading && props.entityList.length > 0) {
			// history.push('/user-management')
		}
		this.cancel()
	}
    toggleModal = () => {
		this.setState({
		   modal:!this.state.modal
		})
	}
}

export default withTranslation()(ImportTsvComponent)
